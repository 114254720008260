import { useApolloClient } from '@apollo/client'
import clsx from 'clsx'
import { PageProps, graphql, navigate } from 'gatsby'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import { Page, SkillApi, faqPage, values } from '@karakuri-ai/faq-component'

import { Layout, Maybe } from '../graphql'
import IndexLayout from '../layouts'
import { Session } from '../session'
import { loadDictionary } from '../utils/dictionary'
import {
  GQL_FORM_TOKEN,
  GQL_PULLDOWN,
  PulldownEvent,
  PulldownRequest,
  PulldownResponse,
  SkillTokenDataV2,
  SkillTokenVars,
  isPulldownEvent,
} from '../widgets/graphql'
import { AnswerData, AnswerPageContext, CategoryDictionary, Hierarchy } from '../widgets/interface'
import { renderAnswerPageWidget } from '../widgets/pages'
import { SEOWidget as SEO } from '../widgets/SEOWidget'

const availableSkills = ['256c536b-e6e5-7718-ef93-21e83e06d502']
function toApiUrl(botId?: string | null) {
  const bot = botId || 'dev'
  // eslint-disable-next-line compat/compat
  const hostname =
    (typeof window !== 'undefined' && window.location.host.replace(/:\d+$/, '')) || ''
  if (hostname === 'localhost') {
    return '//localhost:9999'
  } else if (/\.ninja$/.test(hostname)) {
    return `//${bot}.karakuri.ninja`
  }
  return `//${bot}.karakuri.ai`
}

function mainLayout(layout?: Maybe<Maybe<Layout>[]>): Layout[] {
  const defaultLayout: Layout[] = [
    { id: 'navi', type: 'navi' },
    { id: 'answer', type: 'answer' },
  ]
  if (!layout) {
    return defaultLayout
  }
  const main = values(layout, defaultLayout)
  if (main.length === 0) {
    return defaultLayout
  }
  return main
}

function pickHeaderTitle(
  setting?: Maybe<AnswerData['settingYaml']>,
  parentTitle?: Maybe<string>,
  title?: Maybe<string>,
  hierarchies?: Hierarchy[]
): string {
  if (setting?.headerTitleType === 'question') {
    const h = hierarchies || []
    const last = h.length > 0 ? h[h.length - 1] : undefined
    if (last?.link.indexOf('/answer') === 0) {
      return title || ''
    }
  }
  return parentTitle || title || ''
}

type Props = PageProps<AnswerData, AnswerPageContext, { hierarchies?: Hierarchy[] }>
function AnswerPage({ data, pageContext, location }: Props) {
  const client = useApolloClient()
  const { ask, contents } = pageContext
  const { url, tagCloud, theme } = data.settingYaml || {}
  const [dictionary, setDictionary] = useState<CategoryDictionary>({})
  useEffect(() => {
    loadDictionary().then(dictionary => setDictionary(dictionary))

    function handleSkill(event: MessageEvent<PulldownEvent>) {
      if (isPulldownEvent(event.data)) {
        client
          .query<PulldownResponse, PulldownRequest>({
            query: GQL_PULLDOWN,
            variables: {
              accountId: data.settingYaml.botId || '',
              query: event.data.query.text,
            },
          })
          .then(response => {
            if (!response.data?.pulldownQuery?.id) {
              return
            }
            navigate(`/answer/${response.data.pulldownQuery.id}`)
          })
      }
    }
    window.addEventListener('message', handleSkill)
    return () => {
      window.removeEventListener('message', handleSkill)
    }
  }, [client, data.settingYaml.botId])
  const breadcrumb = {
    current: pageContext.ask || '',
    url: url || '',
    hierarchies: location.state?.hierarchies ? location.state.hierarchies : [],
  }

  const skillApi: SkillApi = {
    baseUrl: toApiUrl(data.settingYaml.botId),
    customer: { session: Session.value },
    params: { color: theme?.secondaryColor },
    token: (skillId, param) =>
      client
        .query<SkillTokenDataV2, SkillTokenVars>({
          query: GQL_FORM_TOKEN,
          variables: {
            accountId: data.settingYaml.botId || '',
            session: Session.get(),
            skillId,
            param,
          },
        })
        .then(result => result.data.skillTokenV2),

    isAvailableSkill: path => {
      return availableSkills.some(s => path.indexOf(s) !== -1)
    },
  }
  const parent =
    (breadcrumb.hierarchies &&
      breadcrumb.hierarchies.length > 0 &&
      breadcrumb.hierarchies[breadcrumb.hierarchies.length - 1]) ||
    undefined
  const title = pickHeaderTitle(
    data.settingYaml,
    parent?.title,
    pageContext?.ask,
    breadcrumb.hierarchies
  )

  const widgetProps = {
    data,
    breadcrumb,
    tagCloud: !!tagCloud,
    pageContext,
    skillApi,
    location,
    dictionary,
  }
  const layout = data.settingYaml.layout || 'one'
  const left = layout === 'one' ? [] : values(data.settingYaml.answerLayoutLeft, [])
  const right = layout === 'three' ? values(data.settingYaml.answerLayoutRight, []) : []
  const main = mainLayout(data.settingYaml.answerLayoutMain)

  return (
    <IndexLayout className="faq-answer-layout">
      <SEO
        pathname={location.pathname}
        title={pageContext.ask || ''}
        disableDefaultDescription={true}
      />
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(faqPage(ask, contents))}</script>
      </Helmet>
      <Page className="faq-content faq-answer-page">
        <div className="faq-main-content">
          <div className="faq-header">
            <h2>{title}</h2>
          </div>
          {renderAnswerPageWidget(main, widgetProps)}
        </div>

        <div className={clsx('faq-left-content', { 'faq-no-content': left.length === 0 })}>
          {renderAnswerPageWidget(left, widgetProps)}
        </div>
        <div className={clsx('faq-right-content', { 'faq-no-content': right.length === 0 })}>
          {renderAnswerPageWidget(right, widgetProps)}
        </div>
      </Page>
    </IndexLayout>
  )
}

export default AnswerPage

export const query = graphql`
  query AnswerQuery {
    settingYaml(ignore: { ne: true }) {
      botId
      url
      tagCloud
      layout
      answerLayoutMain {
        ...Layout
      }
      answerLayoutLeft {
        ...Layout
      }
      answerLayoutRight {
        ...Layout
      }
      skillApiToken
      questionnaire {
        notSelected
        yes
        no
        yesForm
        noForm
      }
      theme {
        mainColor
        secondaryColor
      }
      words {
        breadcrumb {
          top
        }
        search {
          resultMessage {
            success
            error
          }
        }
      }
      headerTitleType
      waitMs {
        search
      }
      searchType
      queryThreshold
    }
  }
`
